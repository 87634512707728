import enUS from './en-US.json';
import esMX from './es-MX.json';
import ptBR from './pt-BR.json';

const config = {
  'en-US': enUS,
  'es-MX': esMX,
  'pt-BR': ptBR,
  'spanish': esMX,
  'english': enUS,
};

export const DEFAULT_LANG = 'es-MX';

export const supported = Object.keys(config);

export default config;
