/* global $ */
import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import '../vendor/taplytics';
import Datepicker from 'async!./datepicker';
import PassengersDropdown from 'async!./passengersDropdown';
import BannerBranding from 'async!./BannerBranding';
import RoundSwitch from 'async!./RoundSwitch';
import { initTest } from '../utils/abtest';
import validate from '../utils/validate';
import resultsUrl from '../utils/resultsUrl';
import decodePassengers from '../utils/decodePassengers';
import searchFromQuery from '../utils/searchFromQuery';
import validateDate from '../utils/validateDate';
import stringToDate from '../utils/stringToDate';
import createUrl from '../utils/createUrl';
import gtmSetDataLayer from '../utils/gtmSetDataLayer';
import recentTripListener from '../utils/recentTripListener';
import mixpanelTracker from '../utils/mixpanelTracker';
import WidgetTitle from './WidgetTitle';
import '../style.scss';
import PaymentMethods from './PaymentMethods';

const getInterestInSearchEventHandler = section => () => {
  mixpanelTracker.trackInterestInSearchEvent({
    section,
  });
};

class Search extends Component {
  state = {
    errors: {},
    passengers: {},
    tripType: 'oneWay',
    hybridTrip: 'none', // none, departure, return, both
    recentTrips: [],
    usingNearbyTerminal: false,
    loadingNearbyTerminal: false,
  };

  fieldClasses = {
    origin: 'input.origin',
    destination: 'input.destination',
    departureDate: 'input.departureDate',
    returnDate: 'input.returnDate',
  };

  constructor(props) {
    super(props);

    this.showPaymentMethods = true;
    this.showDiscountsAB = true;
    this.showTextPromAB = true;

    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
    this.onChangePassengers = this.onChangePassengers.bind(this);
    this.onChangeDepartureDate = this.onChangeDepartureDate.bind(this);
    this.onChangeReturnDate = this.onChangeReturnDate.bind(this);
    this.receiveRecentTrips = this.receiveRecentTrips.bind(this);
    this.setGtmProperties = this.setGtmProperties.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.disposeListener = recentTripListener(this.receiveRecentTrips);
    this.setLoadingNearbyTerminal = this.setLoadingNearbyTerminal.bind(this);
    this.setUsingNearbyTerminal = this.setUsingNearbyTerminal.bind(this);
    this.onChangeDepartureToOpenTicket = this.onChangeDepartureToOpenTicket.bind(this);
    this.onChangeReturnToOpenTicket = this.onChangeReturnToOpenTicket.bind(this);
    this.onRemoveReturnOpenTicket = this.onRemoveReturnOpenTicket.bind(this);
    this.onRemoveDepartureOpenTicket = this.onRemoveDepartureOpenTicket.bind(this);
  }

  componentDidMount() {
    this.setInitialState();
    const { config } = this.props;
    const { optInReturn } = config;

    this.initAbTests();

    if (optInReturn) {
      const { tripType } = this.state;

      if (tripType === 'oneWay') {
        this.$get('returnDate').hide();
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ returnDate: null });
      }
    }

    this.emitSearchLoaded();
  }

  componentDidUpdate(_, { tripType: prevTripType }) {
    const { config } = this.props;
    const { optInReturn } = config;
    const { tripType } = this.state;

    if (!optInReturn || tripType === prevTripType) return;

    if (tripType === 'round') {
      this.$get('returnDate').show();
    } else if (tripType === 'oneWay') {
      this.$get('returnDate').hide();
    }
  }

  componentWillUnmount() {
    this.disposeListener();
  }

  onChangeAutocomplete(field, place) {
    const { origin, destination } = place;
    const currentState = this.state;
    const { errors } = currentState;

    if (origin && destination) {
      this.setState({ origin, destination });
    } else {
      this.setState({ [field]: place });

      if (errors[field] && Object.keys(place).length > 0) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [field]: '',
          },
        }));
      }
    }

    this.setGtmProperties();
  }

  onChangeDepartureToOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'none') {
      this.setState({ hybridTrip: 'departure' });
    } else if (hybridTrip === 'return') {
      this.setState({ hybridTrip: 'both' });
    }
  }

  onChangeReturnToOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'none') {
      this.setState({ hybridTrip: 'return' });
    } else if (hybridTrip === 'departure') {
      this.setState({ hybridTrip: 'both' });
    }
  }

  onRemoveReturnOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'both') {
      this.setState({ hybridTrip: 'departure' });
    } else if (hybridTrip === 'return') {
      this.setState({ hybridTrip: 'none' });
    }
  }

  onRemoveDepartureOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'both') {
      this.setState({ hybridTrip: 'return' });
    } else if (hybridTrip === 'departure') {
      this.setState({ hybridTrip: 'none' });
    }
  }

  onChangeDepartureDate(departureDate) {
    const { openTicketText, config } = this.props;
    const { allowHybridTrip } = config;
    const isOpenDate = departureDate === openTicketText;
    this.setState({ departureDate });

    if (!isOpenDate) {
      this.onRemoveDepartureOpenTicket();
    }

    // If hybrid trip is not allowed, but departure is open date
    // Then return date should be cleared and disabled
    if (!allowHybridTrip && isOpenDate) {
      this.setState({ returnDate: '' });
      this.onRemoveReturnOpenTicket();
      this.$get('returnDate').trigger('clearDate');
    }

    if (departureDate !== '') {
      this.$get('returnDate').trigger('changeDepartureDate', departureDate);
    }

    const { errors } = this.state;
    if (errors.departureDate && departureDate !== '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          departureDate: '',
        },
      }));
    }

    this.setGtmProperties();
  }

  onChangeReturnDate(returnDate) {
    const { openTicketText } = this.props;
    this.setState({ returnDate });

    if (returnDate !== openTicketText) {
      this.onRemoveReturnOpenTicket();
    }

    if (returnDate !== '') {
      this.$get('departureDate').trigger('changeReturnDate', returnDate);
    }

    const { errors } = this.state;
    if (errors.returnDate && returnDate !== '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          returnDate: '',
        },
      }));
    }

    this.setGtmProperties();
  }

  onChangePassengers(type, quantity) {
    const { passengers: currentPassengers } = this.state;
    const passengers = {
      ...currentPassengers,
      [type]: quantity,
    };

    this.setState({ passengers });
    this.setGtmProperties();
  }

  onSubmit() {
    if (!this.validate()) return;

    const { config } = this.props;

    const searchType = config.secondRedirectEnabled ? 'secondRedirect' : 'bus';
    const url = resultsUrl(this.state, config, searchType);

    const { openNewWindow, forceHttps, lang } = config;

    let urlFormatted = `${window.location.protocol}${url}`;
    if (forceHttps) urlFormatted = `https:${url}`;

    urlFormatted = this.getAnalyticsParams(urlFormatted);
    urlFormatted = this.getAnalyticsABParams(urlFormatted);

    if (lang) urlFormatted = this.setLanguageParam(urlFormatted, lang);

    if (openNewWindow) {
      window.open(urlFormatted);
    } else {
      window.top.location.href = urlFormatted;
    }
  }

  setLanguageParam(url, lang) {
    const newUrl = new URL(url);
    newUrl.searchParams.append('lang', lang);
    return newUrl.toString();
  }

  getAnalyticsABParams(url) {
    const { config } = this.props;
    const { destination } = this.state;
    const { abtestAutoSelectOrigin, abtestDestinationHit, abtestSelectedTripType } = config;

    // If every item in the array is false, then return url
    if (
      [abtestAutoSelectOrigin, abtestDestinationHit, abtestSelectedTripType].every(item => !item)
    ) {
      return url;
    }

    const prefix = 'analytics_';

    const newUrl = new URL(url);
    if (abtestSelectedTripType)
      newUrl.searchParams.append(`${prefix}round_trip_ab`, this.abRoundTrip);

    if (abtestAutoSelectOrigin)
      newUrl.searchParams.append(`${prefix}geo_place_used_ab`, this.abOriginAutoSelect);

    newUrl.searchParams.append(`${prefix}destination_hit_order_ab`, this.abDestinationHit);
    if (abtestDestinationHit && !this.abDestinationHit) return newUrl.toString();

    newUrl.searchParams.append(`${prefix}destination_hit_order`, destination.position);
    return newUrl.toString();
  }

  getAnalyticsParams(url) {
    const { config } = this.props;
    const { usingNearbyTerminal } = this.state;
    const { autoSelectOrigin } = config;
    const prefix = 'analytics_';

    // If analytics is not defined, then return url
    if (!usingNearbyTerminal) {
      return url;
    }

    const newUrl = new URL(url);
    if (autoSelectOrigin)
      newUrl.searchParams.append(`${prefix}geo_place_used`, usingNearbyTerminal);

    return newUrl.toString();
  }

  setLoadingNearbyTerminal(value) {
    this.setState({ loadingNearbyTerminal: value });
  }

  setUsingNearbyTerminal(value) {
    this.setState({ usingNearbyTerminal: value });
  }

  setGtmProperties() {
    const { config } = this.props;
    const { origin = {}, destination = {}, departureDate, returnDate, passengers } = this.state;

    if (config.useGtm) {
      gtmSetDataLayer({
        originCity: origin.cityName,
        originState: origin.state,
        originCountry: origin.country,
        destinationCity: destination.cityName,
        destinationState: destination.state,
        destinationCountry: destination.country,
        startDate: stringToDate(departureDate),
        endDate: stringToDate(returnDate),
        travelers: passengers,
      });
    }
  }

  getMixpanelSearchLoadedProps() {
    const { config } = this.props;
    const {
      abtestAutoSelectOrigin,
      abtestDestinationHit,
      abtestSelectedTripType,
      paymentBenefitsAB,
      discountsAB,
      textPromAB,
    } = config;

    return {
      ...(abtestAutoSelectOrigin && {
        'Geo Place Used Ab Result': this.abOriginAutoSelect ? 'a' : 'b',
      }),
      ...(abtestDestinationHit && {
        'Destination Hit Order Ab Result': this.abDestinationHit ? 'a' : 'b',
      }),
      ...(abtestSelectedTripType && {
        'Round Trip Ab Result': this.abRoundTrip ? 'a' : 'b',
      }),
      ...(paymentBenefitsAB && {
        'Search Payment Methods Ab Result': this.showPaymentMethods ? 'a' : 'b',
      }),
      ...(discountsAB && {
        'Search Discounts Ab Result': this.getSearchDiscountsABResult(),
      }),
      ...(textPromAB && {
        'Search TextProm Ab Result': this.showTextPromAB ? 'a' : 'b',
      }),
    };
  }

  getSearchDiscountsABResult() {
    const { config } = this.props;
    const { discountSingle, discountRounded, discountOpenTicket } = config;

    if (!this.showDiscountsAB) return 'b';
    if (discountSingle && discountRounded && discountOpenTicket) return 'c';
    return 'a';
  }

  initAbTests() {
    const { config } = this.props;
    const {
      abtestAutoSelectOrigin,
      abtestDestinationHit,
      abtestSelectedTripType,
      paymentBenefitsAB,
      discountsAB,
      textPromAB,
    } = config;

    if (abtestAutoSelectOrigin) this.abTestAutoSelect();
    if (abtestDestinationHit) this.abTestDestinationHit();
    if (abtestSelectedTripType) this.abTestSelectedTripType();
    if (paymentBenefitsAB) this.paymentBenefitsAB();
    if (discountsAB) this.discountsAB();
    if (textPromAB) this.textPromAB();
  }

  abTestAutoSelect() {
    const abResult = initTest(50, 'AUTO-SELECT-ORIGIN-AB');
    this.abOriginAutoSelect = abResult === 'a';
  }

  abTestDestinationHit() {
    const abResult = initTest(50, 'DESTINATION-HIT-AB');
    this.abDestinationHit = abResult === 'a';
  }

  abTestSelectedTripType() {
    const abResult = initTest(50, 'SELECTED-TRIP-TYPE-AB');
    this.abRoundTrip = abResult === 'a';

    this.setState({ tripType: this.abRoundTrip ? 'round' : 'oneWay' });
  }

  paymentBenefitsAB() {
    const abResult = initTest(50, 'paymentBenefitsAB');
    this.showPaymentMethods = abResult === 'a';
  }

  discountsAB() {
    const abResult = initTest(50, 'discountsAB');
    this.showDiscountsAB = abResult === 'a';
  }

  textPromAB() {
    const abResult = initTest(50, 'textPromAB');
    this.showTextPromAB = abResult === 'a';
  }

  emitSearchLoaded() {
    const { config } = this.props;
    const { interestEvent } = config;
    mixpanelTracker.trackEvent(interestEvent, this.getMixpanelSearchLoadedProps());
  }

  receiveRecentTrips(recentTrips) {
    this.setState({ recentTrips });
  }

  setInitialState() {
    const { config, openTicketText } = this.props;
    const {
      maxDaysSearch,
      accentColor,
      primaryColor,
      passengers,
      departureDate,
      returnDate,
      destination,
      origin,
    } = config;
    const {
      departureDate: qDepartureDate,
      destination: qDestination,
      origin: qOrigin,
      passengers: qPassengers,
      returnDate: qReturnDate,
    } = searchFromQuery();

    const initialDeparture = qDepartureDate || departureDate;
    const initialDestination = { slug: qDestination || destination };
    const initialOrigin = { slug: qOrigin || origin };
    const initialPassengers = qPassengers || passengers;
    const initialReturn = qReturnDate || returnDate;
    const initialDepartureIsOpenTicket = initialDeparture === openTicketText;
    const initialReturnIsOpenTicket = initialReturn === openTicketText;

    this.setState({
      departureDate: validateDate(initialDeparture, maxDaysSearch, openTicketText)
        ? initialDeparture
        : null,
      destination: initialDestination,
      origin: initialOrigin,
      passengers: decodePassengers(initialPassengers),
      returnDate: validateDate(initialReturn, maxDaysSearch, openTicketText) ? initialReturn : null,
    });

    // Check if initial dates are open trips
    // If departure is open ticket, set hybridTrip to departure
    if (initialDepartureIsOpenTicket) {
      this.setState({
        hybridTrip: 'departure',
      });
    }

    // If return is open ticket, set hybridTrip to return
    // If both are open ticket, set hybridTrip to both
    if (initialReturnIsOpenTicket) {
      if (initialDepartureIsOpenTicket) {
        this.setState({
          hybridTrip: 'both',
        });
      } else {
        this.setState({
          hybridTrip: 'return',
        });
      }
    }

    // Clicktripz initial data
    this.setGtmProperties();

    if (departureDate) {
      this.$get('returnDate').trigger('changeDepartureDate', departureDate);
    }

    if (returnDate) {
      this.$get('departureDate').trigger('changeReturnDate', returnDate);
    }

    this.rootEl.style.setProperty('--accent-500', accentColor);
    this.rootEl.style.setProperty('--primary-700', primaryColor);
  }

  validate() {
    const currentState = this.state;
    const errors = validate(currentState);
    this.setState({ errors });

    console.log(errors);

    return Object.keys(errors).length === 0;
  }

  $get(fieldName) {
    return $(this.rootEl).find(this.fieldClasses[fieldName]);
  }

  render(props, state) {
    const { config, buttonSearch } = this.props;
    const {
      origin,
      tripType,
      errors,
      passengers,
      departureDate,
      returnDate,
      destination,
      recentTrips,
      usingNearbyTerminal,
      loadingNearbyTerminal,
      hybridTrip,
    } = state;

    const {
      Autocomplete,
      sourceUrl,
      maxDaysSearch,
      calendarOpen,
      allowHybridTrip,
      passengersDropdown,
      optInReturn,
      discountSingle,
      discountRounded,
      discountOpenTicket,
      redBadge,
      showOpenTicket,
      brandingCopy,
      widgetTitle,
      line,
      airline,
      transporter,
      showErrors,
      displayType,
      autoSelectOrigin,
      abtestAutoSelectOrigin,
      abtestDestinationHit,
      destination: defaultDestination,
      cardsAvailable,
      oxxoAvailable,
      paypalAvailable,
      coppelpayAvailable,
      departureDatePickerText,
      returnDatePickerText,
      dateFormat,
    } = config;

    const hideReturn = optInReturn && tripType === 'oneWay';
    const protocol = /https?:/.test(window.location.protocol) ? 'https:' : 'http:';
    const autocompleteUrl = createUrl(`${protocol}//${sourceUrl}`);
    const showOpenTicketCTAOnReturn = calendarOpen && allowHybridTrip;
    const disableReturnDate = !allowHybridTrip && hybridTrip !== 'none';

    if (line) {
      autocompleteUrl.setQueryParam('line', line);
    } else if (airline) {
      autocompleteUrl.setQueryParam('airline', airline);
    } else if (transporter) {
      autocompleteUrl.setQueryParam('transporter', transporter);
    }
    const hasAutoSelectOrigin =
      autoSelectOrigin && abtestAutoSelectOrigin ? this.abOriginAutoSelect : autoSelectOrigin;

    const orderDestinationHit = abtestDestinationHit ? this.abDestinationHit : true;

    const paymentMethods = cardsAvailable && this.showPaymentMethods && (
      <div className="payment-wrapper">
        <PaymentMethods
          cardsAvailable={cardsAvailable}
          oxxoAvailable={oxxoAvailable}
          paypalAvailable={paypalAvailable}
          coppelpayAvailable={coppelpayAvailable}
        />
      </div>
    );

    const discountsProps = this.showDiscountsAB && {
      discountSingle,
      discountRounded,
      discountOpenTicket,
    };

    const textPromPicker = this.showTextPromAB && {
      departureDatePickerText,
      returnDatePickerText,
    };

    return (
      <form ref={el => (this.rootEl = el)} onSubmit={e => e.preventDefault()} className="grid-form">
        {brandingCopy && <BannerBranding copy={brandingCopy} />}

        {widgetTitle && (
          <div className="title-wrapper">
            <WidgetTitle copy={widgetTitle} />
          </div>
        )}

        {(showOpenTicket || optInReturn || cardsAvailable) && (
          <div className="switch-wrapper">
            <div className="switch">
              <RoundSwitch
                value={tripType}
                onChange={newTripType => this.setState({ tripType: newTripType })}
                showOpenTicket={showOpenTicket}
                optInReturn={optInReturn}
                {...discountsProps}
                redBadge={redBadge}
              />
            </div>
            {paymentMethods}
          </div>
        )}

        <div className="search-form">
          <div className="form-field">
            <Autocomplete
              field="origin"
              sourceUrl={autocompleteUrl.href}
              onChange={this.onChangeAutocomplete}
              to={destination && destination.slug}
              initialPlaceSlug={origin && origin.slug}
              place={origin}
              error={Boolean(errors.origin)}
              placeholder="origin"
              recentTrips={recentTrips}
              displayType={displayType}
              autoSelectOrigin={hasAutoSelectOrigin}
              usingNearbyTerminal={usingNearbyTerminal}
              loadingNearbyTerminal={loadingNearbyTerminal}
              setLoadingNearbyTerminal={this.setLoadingNearbyTerminal}
              setUsingNearbyTerminal={this.setUsingNearbyTerminal}
              onFocus={getInterestInSearchEventHandler('Origin')}
            />
            {showErrors && <span className="fieldError">{errors.origin}</span>}
          </div>

          <div className="form-field">
            <Autocomplete
              field="destination"
              sourceUrl={autocompleteUrl.href}
              onChange={this.onChangeAutocomplete}
              from={origin && origin.slug}
              initialPlaceSlug={defaultDestination}
              place={destination}
              error={Boolean(errors.destination)}
              placeholder="destination"
              displayType={displayType}
              autoSelectOrigin={hasAutoSelectOrigin}
              usingNearbyTerminal={false}
              loadingNearbyTerminal={loadingNearbyTerminal}
              setLoadingNearbyTerminal={this.setLoadingNearbyTerminal}
              setUsingNearbyTerminal={this.setUsingNearbyTerminal}
              orderDestinationHit={orderDestinationHit}
              onFocus={getInterestInSearchEventHandler('Destination')}
            />
            {showErrors && <span className="fieldError">{errors.destination}</span>}
          </div>

          <div className={`form-field-date-wrap ${tripType === 'openTicket' ? 'hide' : ''}`}>
            <div className={`form-field-date ${hideReturn ? 'border-radius' : ''}`}>
              <Datepicker
                field="departureDate"
                onClose={this.onChangeDepartureDate}
                error={Boolean(errors.departureDate)}
                placeholder="departure_date"
                maxDaysSearch={maxDaysSearch}
                calendarOpen={calendarOpen}
                onOpenTicketDate={this.onChangeDepartureToOpenTicket}
                date={departureDate}
                onFocus={getInterestInSearchEventHandler('Departure Date')}
                {...textPromPicker}
                dateFormat={dateFormat}
              />
              {showErrors && <span className="fieldError">{errors.departureDate}</span>}
            </div>

            <div className={`form-field-date ${hideReturn ? 'hide' : ''}`}>
              <Datepicker
                field="returnDate"
                onClose={this.onChangeReturnDate}
                error={Boolean(errors.returnDate)}
                hide={hideReturn}
                placeholder={`return${!optInReturn ? '_option' : ''}`}
                maxDaysSearch={maxDaysSearch}
                calendarOpen={showOpenTicketCTAOnReturn}
                onOpenTicketDate={this.onChangeReturnToOpenTicket}
                date={returnDate}
                onFocus={getInterestInSearchEventHandler('Return Date')}
                disabled={disableReturnDate}
                {...textPromPicker}
                dateFormat={dateFormat}
              />
              {showErrors && <span className="fieldError">{errors.returnDate}</span>}
            </div>
          </div>

          {passengersDropdown && (
            <div className="form-field dropdown">
              <PassengersDropdown
                onUpdatePassengers={this.onChangePassengers}
                error={Boolean(errors.passengers)}
                passengers={passengers}
                onClick={getInterestInSearchEventHandler('Passengers')}
              />
              {showErrors && <span className="fieldError">{errors.passengers}</span>}
            </div>
          )}

          <div className="button-wrapper">
            <button
              type="submit"
              onClick={this.onSubmit}
              className="search-button"
              ct-submit="true" // Clicktripz
              onFocus={getInterestInSearchEventHandler('Search Button')}
            >
              {buttonSearch}
            </button>
          </div>
        </div>

        {paymentMethods}
      </form>
    );
  }
}

export default withText({
  buttonSearch: <Text id="button.search" />,
  openTicketText: <Text id={`label.open_ticket`} />,
})(Search);
