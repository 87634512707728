/* global $ */
import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import AutocompleteTypeahead from 'async!./AutocompleteTypeahead';
import OccupancyPicker from 'async!./OccupancyPicker';
import DatePicker from 'async!./datepicker';
import createUrl from '../utils/createUrl';
import validateHotels from '../utils/validateHotels';
import '../style.scss';
import resultsUrl from '../utils/resultsUrl';

class HotelSearch extends Component {
  state = {
    place: { slug: '' },
    startDate: null,
    endDate: null,
    guests: {
      adult: 1,
      children: [],
    },
    errors: {},
  };

  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
  }

  onSubmit() {
    if (!this.validate()) return;
    const { config } = this.props;

    const url = resultsUrl(this.state, config, 'hotel');
    window.location.href = url;
  }

  onChangeStartDate(startDate) {
    this.setState({ startDate });

    if (startDate !== '') {
      this.$get('input.endDate').trigger('changeDepartureDate', startDate);
    }
  }

  onChangeEndDate(endDate) {
    this.setState({ endDate });

    if (endDate !== '') {
      this.$get('input.startDate').trigger('changeReturnDate', endDate);
    }
  }

  validate() {
    const newErrors = validateHotels(this.state);
    this.setState({ errors: newErrors });

    const { errors } = this.state;
    return Object.keys(errors).length === 0;
  }

  $get(fieldName) {
    return $(this.rootRef).find(fieldName);
  }

  render() {
    const { guests, errors, place, startDate, endDate } = this.state;
    const { config, button_search } = this.props;
    const { sourceUrl } = config;

    const protocol = /https?:/.test(window.location.protocol) ? 'https:' : 'http:';
    const autocompleteUrl = createUrl(`${protocol}//${sourceUrl}`);

    return (
      <form
        ref={ref => (this.rootRef = ref)}
        onSubmit={e => e.preventDefault()}
        className="reservamos-search"
      >
        <div className="search-form">
          <div className="form-field">
            <AutocompleteTypeahead
              field="place"
              sourceUrl={autocompleteUrl.href}
              placeholder="destination"
              place={place}
              error={Boolean(errors.place)}
              onChange={(_, newPlace) => this.setState({ place: newPlace })}
              country="mexico"
              isHotelSearch
            />
          </div>
          <div className="form-field-date-wrap">
            <div className="form-field-date">
              <DatePicker
                field="startDate"
                error={Boolean(errors.startDate)}
                placeholder="arrival_date"
                maxDaysSearch="365"
                date={startDate}
                onClose={this.onChangeStartDate}
              />
            </div>
            <div className="form-field-date">
              <DatePicker
                field="endDate"
                error={Boolean(errors.endDate)}
                placeholder="departure_date"
                maxDaysSearch="365"
                date={endDate}
                onClose={this.onChangeEndDate}
              />
            </div>
          </div>
          <div className="form-field dropdown">
            <OccupancyPicker
              guests={guests}
              onChange={newGuests => this.setState({ guests: newGuests })}
            />
          </div>
          <div className="button-wrapper">
            <button type="submit" onClick={this.onSubmit} className="search-button">
              {button_search}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withText(() => ({
  button_search: <Text id="button.search" />,
}))(HotelSearch);
