import titleize from '../../utils/titleize';

export function formatCityDisplay(isPackagesSearch, place) {
  const cityDisplay = isPackagesSearch ? '' : `, ${titleize(place.display)}`;
  return `${titleize(place.city_name)}${cityDisplay}`;
}

export function formatStateDisplay(place) {
  return titleize(`${place.display}, ${place.state}`);
}

export function formatDisplayType(displayType, place, isPackagesSearch) {
  switch (displayType) {
    case 'city':
    case 'city_state':
      return formatCityDisplay(isPackagesSearch, place);

    case 'state':
      return formatStateDisplay(place);

    default:
      return formatStateDisplay(place);
  }
}
