import { useEffect, useRef } from 'preact/compat';
import { useState } from 'preact/hooks';
import { Text, Localizer } from 'preact-i18n';
import { camelizeKeys } from 'humps';
import createUrl from '../../utils/createUrl';
import normalize from '../../utils/normalize';
import mixpanelTracker from '../../utils/mixpanelTracker';
import SelectContext from './context';
import SelectContainer from './SelectContainer';
import { formatDisplayType } from './displayTypes';

require('../../styles/AutocompleteSelect.min.css');

const AutocompleteSelect = ({
  field,
  error,
  hasArrow,
  placeholder,
  sourceUrl,
  displayType,
  isPackagesSearch,
  onChange,
  place: placeProp,
  from,
  to,
  autoSelectOrigin,
  usingNearbyTerminal,
  setUsingNearbyTerminal,
  loadingNearbyTerminal,
  setLoadingNearbyTerminal,
  orderDestinationHit,
  initialPlaceSlug,
}) => {
  const [input, setInput] = useState('');
  const [places, setPlaces] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [originsLoaded, setOriginsLoaded] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);

  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const optionsRef = useRef(null);

  const handleClickOutside = event => {
    if (containerRef && !containerRef.current.contains(event.target)) {
      setShowItems(false);
    }
  };

  const placesUrl = () => {
    const url = createUrl(sourceUrl);

    if (from) {
      url.setQueryParam('from', from);
      if (orderDestinationHit) url.setQueryParam('order_by', 'origin_hits');
    } /* else if (to) {
      url.setQueryParam('from', to);
      if (orderDestinationHit) url.setQueryParam('order_by', 'origin_hits');
    } */ // TODO Add queryParam to search places based on pre-selected destination
    else {
      url.setQueryParam('prefetch', 'true');
      if (orderDestinationHit) url.setQueryParam('order_by', 'origin_hits');
    }
    return url.href;
  };

  const fetchTerminals = (showAfterFetch = true) => {
    setOptionsLoaded(false);

    if (!loadingNearbyTerminal && showAfterFetch) {
      setShowItems(true);
    }

    fetch(placesUrl())
      .then(response => response.json())
      .then(data => {
        setOptionsLoaded(true);
        setOriginsLoaded(true);
        setOptions(data);
        setPlaces(data);
      })
      .then(() => {
        if (!loadingNearbyTerminal && showAfterFetch) {
          optionsRef.current.scrollTo(null, 0);
          inputRef.current.focus();
        } else {
          setLoadingNearbyTerminal(false);
        }
      });
  };

  const fetchTerminalBy = slug => {
    setOptionsLoaded(false);
    fetch(placesUrl())
      .then(response => response.json())
      .then(data => {
        setOriginsLoaded(true);
        setOptionsLoaded(true);
        setOptions(data);
        setPlaces(data);

        if (data && data.length > 0) {
          const place = data.find(p => {
            return p.slug === slug;
          });
          onChange(field, camelizeKeys(place));
          setInput(formatDisplayType(displayType, place, isPackagesSearch));
        }
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Auto select destination if initialPlaceSlug exists
    if (field === 'destination' && initialPlaceSlug) {
      fetchTerminalBy(initialPlaceSlug);
    }

    // Auto select origin if autoSelectOrigin is true
    if (field === 'origin' && autoSelectOrigin && initialPlaceSlug) {
      fetchTerminalBy(initialPlaceSlug);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleElementSelect = value => {
    setInput(value);
    setShowItems(false);
    if (usingNearbyTerminal) setUsingNearbyTerminal(false);
  };

  const fetchNearTerminals = () => {
    const nearTerminalsUrl = `${sourceUrl}/near-to-user`;

    fetch(nearTerminalsUrl)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          const place = data[0];
          setUsingNearbyTerminal(true);
          setLoadingNearbyTerminal(true);
          onChange(field, camelizeKeys(place));
          setInput(formatDisplayType(displayType, place, isPackagesSearch));
        } else {
          setUsingNearbyTerminal(false);
        }
      });
  };

  useEffect(() => {
    if (autoSelectOrigin && field === 'origin') {
      fetchNearTerminals();
    }
  }, [autoSelectOrigin]);

  const getPlaceDisplayText = place => {
    let displayText = '';

    if (displayType === 'city') {
      const cityDisplay = isPackagesSearch ? '' : `, ${place.display}`;
      displayText = `${place.city_name}${cityDisplay}`;
    } else if (displayType === 'city_state') {
      displayText = `${place.city_name}, ${place.state} ${place.display}`;
    } else {
      displayText = `${place.display}, ${place.state}`;
    }

    return normalize(displayText);
  };

  const filterOptions = value => {
    switch (displayType) {
      case 'state':
      case 'city':
        setOptions(places.filter(place => getPlaceDisplayText(place).includes(value)));
        break;

      case 'city_state':
        // eslint-disable-next-line no-case-declarations
        const inputValue = value.split(' ');

        setOptions(
          places.filter(place => inputValue.every(el => getPlaceDisplayText(place).includes(el)))
        );
        break;

      default:
        setOptions(places.filter(place => getPlaceDisplayText(place).includes(value)));
        break;
    }
  };

  useEffect(() => {
    if (field === 'destination') {
      // Remove from destination if selected origin is equal to destination
      if (placeProp && placeProp.slug === from) {
        setInput('');
        onChange(field, {});
      }
      if (!from && !initialPlaceSlug) {
        setInput('');
        onChange(field, {});
        setDisabledInput(true);
      } else {
        setDisabledInput(false);
        fetchTerminals(!placeProp.slug);
        if (!initialPlaceSlug) {
          setInput('');
          onChange(field, {});
        }
      }
    }
  }, [from]);

  useEffect(() => {
    if (field === 'origin' && originsLoaded) {
      // Remove from origin if selected destination is equal to origin
      if (placeProp && placeProp.slug === to) {
        setInput('');
        onChange(field, {});
      }
      fetchTerminals(!placeProp.slug);
    }
  }, [to]);

  const handleInputChange = event => {
    const newInput = normalize(event.target.value);
    setInput(newInput);
    filterOptions(newInput.trim());
    setUsingNearbyTerminal(false);
    if (newInput === '') {
      onChange(field, {});
    }
  };

  const handleInputClick = () => {
    const transformFieldToEvent = {
      origin: 'Origin',
      destination: 'Destination',
    };
    mixpanelTracker.trackInterestInSearchEvent({
      section: transformFieldToEvent[field] || field,
    });
    if ((field === 'origin' || field === 'place') && !originsLoaded) {
      fetchTerminals();
    }

    if (originsLoaded && !showItems) {
      setShowItems(true);
    }
  };

  return (
    <SelectContext.Provider
      value={{
        displayType,
        isPackagesSearch,
        onChange,
        field,
        handleElementSelect,
      }}
    >
      <div
        className={`${field}-wrapper ${error ? 'error' : ''} ${hasArrow ? 'has-arrow' : ''}`}
        key={`${field}-wrapper`}
        ref={containerRef}
        role="presentation"
      >
        <Localizer>
          <input
            type="text"
            autoComplete="off"
            className={`${field} es-input`}
            placeholder={<Text id={`placeholder.${placeholder}`} />}
            disabled={disabledInput}
            onClick={() => handleInputClick()}
            onInput={handleInputChange}
            value={input}
            ref={inputRef}
          />
        </Localizer>
        <SelectContainer
          options={options}
          showItems={showItems}
          optionsLoaded={optionsLoaded}
          optionsRef={optionsRef}
        />
      </div>
    </SelectContext.Provider>
  );
};

export default AutocompleteSelect;
