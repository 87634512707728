import Loading from '../Loading';
import SelectElement from './SelectElement';

const SelectContainer = ({ options, showItems, optionsLoaded, optionsRef }) => {
  return (
    <ul
      className="es-list"
      ref={optionsRef}
      style={{
        display: showItems ? 'block' : 'none',
        top: '49px',
        left: '0px',
        width: '294px',
        paddingLeft: '0px',
        marginTop: '0px',
        overflowY: 'scroll',
      }}
    >
      {!optionsLoaded && <Loading />}
      {options.length > 0 &&
        showItems &&
        options.map((option, index) => {
          return <SelectElement place={option} position={index + 1} />;
        })}
    </ul>
  );
};

export default SelectContainer;
