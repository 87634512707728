const cityIcon = `
<svg class="autocomplete-icon" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>City Icon</title>
    <g id="Reservamos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="desktop-travel&amp;maps" transform="translate(-280.000000, -1143.000000)" fill="#FFFFFF">
            <g id="City-Icon" transform="translate(280.000000, 1143.000000)">
                <rect id="Rectangle" fill-opacity="0" x="0" y="0" width="30" height="30"></rect>
                <g id="ac-city" transform="translate(6.000000, 2.000000)" fill-rule="nonzero" stroke="#686868" stroke-width="1.3685761">
                    <path d="M8.90999996,0 C13.8229739,0 17.8199999,4.05947952 17.8199999,9.04921871 C17.8199999,10.6850661 17.3854394,12.2882357 16.5632444,13.6854351 L9.67071595,26.3634059 C9.53770945,26.5890332 9.29852546,26.7279387 9.03959096,26.7299999 C9.03761096,26.7299999 9.03568046,26.7299999 9.03370046,26.7299999 C8.77699346,26.7299999 8.53830446,26.5952671 8.40282296,26.373511 L1.33001549,13.8077001 C0.459805498,12.3803366 0,10.7350881 0,9.04921871 C0,4.05947952 3.99702598,0 8.90999996,0 Z M8.91329171,12.8699999 C11.1374753,12.8699999 12.8699999,11.0599116 12.8699999,8.90999996 C12.8699999,6.73371026 11.0913905,4.94999998 8.91329171,4.94999998 C6.73519301,4.94999998 4.94999998,6.73371026 4.94999998,8.90999996 C4.94999998,11.0917495 6.72380346,12.8699999 8.91329171,12.8699999 Z" id="icon"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`;

const terminalIcon = `
<svg class="autocomplete-icon" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Terminal Icon</title>
    <defs>
        <path d="M3.54042565,6.05236198 L3.54042565,17.6580797 L1.9669028,17.6580797 L1.9669028,6.05236198 C0.829447222,5.67247046 -2.70894418e-14,4.49001924 -2.70894418e-14,3.09016393 C-2.70894418e-14,1.38351605 1.23285753,0 2.75366423,0 C4.27447091,0 5.50732845,1.38351605 5.50732845,3.09016393 C5.50732845,4.49001924 4.67787973,5.67247046 3.54042565,6.05236198 Z" id="path-1"></path>
        <path d="M11.6822119,24.9533628 L11.6822119,16.1313375 L2.13390203,16.1313375 L2.13390203,2.9237256 L11.6822119,2.9237256 L11.6822119,0 L0.123732147,0 L0.123732147,6.68008035 L0.123732147,19.1059126 L0.123732147,24.9533628 L0.141541678,24.9533628 C0.129806897,25.0329375 0.123732147,25.1142842 0.123732147,25.1970062 C0.123732147,26.1389312 0.911218365,26.9025125 1.88262933,26.9025125 C2.85404613,26.9025125 3.64153235,26.1389312 3.64153235,25.1970062 C3.64153235,25.1142842 3.6354576,25.0329375 3.62371697,24.9533628 L11.6822119,24.9533628 Z" id="path-3"></path>
        <path d="M1.55446497,2.93249099 C2.38710294,2.93249099 3.0620953,2.27799293 3.0620953,1.47062877 C3.0620953,0.663263442 2.38710294,0.00876655055 1.55446497,0.00876655055 C0.721827003,0.00876655055 0.0468404848,0.663263442 0.0468404848,1.47062877 C0.0468404848,2.27799293 0.721827003,2.93249099 1.55446497,2.93249099 Z" id="path-5"></path>
    </defs>
    <g id="Reservamos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="desktop-travel&amp;maps" transform="translate(-448.000000, -1143.000000)">
            <g id="Terminal-Icon" transform="translate(448.000000, 1143.000000)">
                <g id="Airport-Icon-Copy" fill="#FFFFFF" fill-opacity="0">
                    <g id="City-Icon-Copy">
                        <rect id="Rectangle" x="0" y="0" width="30" height="30"></rect>
                    </g>
                </g>
                <g id="ac-terminal" transform="translate(5.000000, 2.000000)">
                    <g id="Group-6" transform="translate(0.500000, 9.000000)">
                        <g id="Fill-1-Clipped">
                            <mask id="mask-2" fill="white">
                                <use xlink:href="#path-1"></use>
                            </mask>
                            <g id="path-1"></g>
                            <polygon id="Fill-1" fill="#FFFFFF" fill-rule="nonzero" mask="url(#mask-2)" points="-3.36047397 -0.77693208 7.15351669 -0.77693208 7.15351669 17.5778689 -3.36047397 17.5778689"></polygon>
                        </g>
                        <path d="M3.54042565,17.6580797 L1.9669028,17.6580797 L1.9669028,5.46478519 C0.829447222,5.12177439 0,4.05411666 0,2.79016393 C0,1.24919874 1.23285753,0 2.75366423,0 C4.27447091,0 5.50732845,1.24919874 5.50732845,2.79016393 C5.50732845,4.05411666 4.67788122,5.12177439 3.54042565,5.46478519 L3.54042565,17.6580797 Z" id="Stroke-4" stroke="#686868" stroke-width="0.956252891"></path>
                    </g>
                    <g id="Group" transform="translate(7.500000, 0.000000)">
                        <g id="Fill-7-Clipped" transform="translate(0.177548, 0.000000)">
                            <mask id="mask-4" fill="white">
                                <use xlink:href="#path-3"></use>
                            </mask>
                            <g id="path-3"></g>
                            <polygon id="Fill-7" fill="#FFFFFF" fill-rule="nonzero" mask="url(#mask-4)" points="-2.79682083 -1.90642076 14.6027648 -1.90642076 14.6027648 26.8089334 -2.79682083 26.8089334"></polygon>
                        </g>
                        <path d="M11.8597603,24.9533639 L11.8597603,16.1313375 L2.31145102,16.1313375 L2.31145102,2.92372501 L11.8597603,2.92372501 L11.8597603,9.23705556e-14 L0.30128056,9.23705556e-14 L0.30128056,6.6800815 L0.30128056,19.1059127 L0.30128056,24.9533639 C0.307354282,25.0329358 0.30128056,25.1142866 0.30128056,25.1970091 C0.30128056,26.1389336 1.08876678,26.9025143 2.06017949,26.9025143 C3.03159279,26.9025143 3.819079,26.1389336 3.819079,25.1970091 C3.819079,25.1142866 3.81300484,25.0329358 3.80126657,24.9533639 L11.8597603,24.9533639 Z" id="Stroke-10" stroke="#686868" stroke-width="1.11562837"></path>
                        <g id="Fill-12-Clipped" transform="translate(2.767155, 19.071721)">
                            <mask id="mask-6" fill="white">
                                <use xlink:href="#path-5"></use>
                            </mask>
                            <g id="path-5"></g>
                            <polygon id="Fill-12" fill-opacity="0.7415" fill="#DDE8F6" fill-rule="nonzero" mask="url(#mask-6)" points="-2.87371248 -2.89765421 5.98264827 -2.89765421 5.98264827 5.83891175 -2.87371248 5.83891175"></polygon>
                        </g>
                        <path d="M4.32162383,22.0042129 C5.15426354,22.0042129 5.82925122,21.3497149 5.82925122,20.5423501 C5.82925122,19.7349859 5.15426354,19.0804878 4.32162383,19.0804878 C3.48898351,19.0804878 2.81399583,19.7349859 2.81399583,20.5423501 C2.81399583,21.3497149 3.48898351,22.0042129 4.32162383,22.0042129 Z" id="Stroke-15" stroke="#686868" stroke-width="1.11562837"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`;

const airportIcon = `
<svg class="autocomplete-icon" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Airport Icon</title>
    <g id="Reservamos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="desktop-travel&amp;maps" transform="translate(-392.000000, -1142.000000)" fill="#FFFFFF">
            <g id="Airport-Icon" transform="translate(392.000000, 1142.000000)">
                <g id="City-Icon-Copy" fill-opacity="0">
                    <rect id="Rectangle" x="0" y="0" width="30" height="30"></rect>
                </g>
                <g id="ac-airport" transform="translate(2.000000, 1.000000)" fill-rule="nonzero" stroke="#686868" stroke-width="1.48615385">
                    <path d="M9.1356164,27.291358 C9.6164384,27.291358 11.6465753,26.6 12.8041096,26.1506174 L12.9287671,26.0987654 L13.0534247,26.1506174 C14.2109589,26.6 16.2410959,27.291358 16.7219178,27.291358 C16.7575343,27.291358 16.7931507,27.2740741 16.8287671,27.2567902 C16.9534247,27.1185185 17.0246575,26.7555556 17.0246575,26.4790124 L14.8164384,24.0592593 L15.3863014,15.9012346 L25.1273973,18.7876543 L25.1273973,16.5407407 L15.3684931,9.108642 L15.3684931,8.95308641 C15.3684931,8.90123457 15.1547945,3.71604939 14.7986301,2.16049383 C14.4780822,0.82962963 12.9465753,0.72592593 12.9287671,0.72592593 C12.9109589,0.72592593 11.4150685,0.84691358 11.1123288,2.16049383 C10.7561644,3.71604939 10.5424657,8.88395061 10.5424657,8.93580248 L10.5424657,9.091358 L0.78356164,16.5234568 L0.78356164,18.7703704 L10.4890411,15.9012346 L11.0589041,24.0592593 L8.8506849,26.4790124 C8.8506849,26.7555556 8.9041096,27.1185185 9.0465753,27.2567902 C9.0643836,27.291358 9.0821918,27.291358 9.1356164,27.291358 Z" id="icon"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`;

export function selectIcon(resultType, isHotelSearch) {
  if (isHotelSearch) return cityIcon;

  switch (resultType) {
    default:
      return null;
    case 'city':
      return cityIcon;
    case 'terminal':
      return terminalIcon;
    case 'airport':
      return airportIcon;
  }
}

export default function(datum, allTerminalsLabel, isHotelSearch = false) {
  const { resultType } = datum;
  let terminalDisplay;

  if (resultType === 'city') {
    terminalDisplay = `
      <span>- ${allTerminalsLabel}</span>
    `;
  } else {
    terminalDisplay = `
      <span>${datum.display}</span>
    `;
  }

  if (isHotelSearch) {
    terminalDisplay = '';
  }

  const iconTemplate = selectIcon(resultType, isHotelSearch);

  return `
    <div class="autocomplete-item">
      ${iconTemplate}

      <p class="place-info">
        <b>${datum.cityName}</b>
        <span>, ${datum.state} </span>
        ${terminalDisplay}
      </p>
    </div>
  `;
}
